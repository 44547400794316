import { useLazyQuery, useQuery } from '@apollo/client';
import * as Schema from '@core_modules/home/service/graphql/schema';

let fetchPolicy = '';
fetchPolicy = 'cache-first';
const USING_INTERNAL = true;
// uncomment this line (set 'no-cache') to test loader/skeleton component
// fetchPolicy = 'no-cache';

const configs = (isUsingInternal) => {
    const context = isUsingInternal ? { request: 'internal' } : {};

    return {
        notifyOnNetworkStatusChange: true,
        context,
    };
};

export const getBannerSlider = () =>
    useQuery(Schema.getBannerSlider, {
        fetchPolicy,
    });

export const getSlider = (options = {}) =>
    useQuery(Schema.getSlider, {
        fetchPolicy: 'no-cache',
        ...options,
    });

export const getFeaturedProducts = (options = {}, config = {}) =>
    useQuery(Schema.getFeaturedProducts(config), {
        fetchPolicy,
        ...options,
    });
export const getCategoryList = (options = {}) =>
    useQuery(Schema.getCategoryList, {
        fetchPolicy,
        ...options,
    });

export const getAllCategory = (options = {}) =>
    useQuery(Schema.getAllCategory, {
        fetchPolicy,
        ...options,
    });

export const getCmsPageConfig = () =>
    useQuery(Schema.getCmsPageConfig, {
        fetchPolicy,
    });

export const getHomePageConfig = () =>
    useQuery(Schema.getHomePageConfig, {
        fetchPolicy,
    });

export const getCustomer = (options = {}) =>
    useLazyQuery(Schema.getCustomer, {
        ...options,
        ...configs(USING_INTERNAL),
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
    });

export const getCmsBlock = (variables) =>
    useQuery(Schema.getCmsBlock, {
        variables,
    });

export const getVoucher = (options = {}) =>
    useQuery(Schema.getVoucher, {
        context: {
            request: 'internal',
        },
        fetchPolicy: 'no-cache',
        ...options,
    });

export const getMission = (options = {}) =>
    useQuery(Schema.getMission, {
        context: {
            request: 'internal',
        },
        fetchPolicy: 'no-cache',
        ...options,
    });

export default {
    getCustomer,
    getCategoryList,
    getAllCategory,
    getBannerSlider,
    getFeaturedProducts,
    getSlider,
    getCmsPageConfig,
    getCmsBlock,
    getHomePageConfig,
    getVoucher,
    getMission,
};
