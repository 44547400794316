/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-return-assign */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import ImageSlide from '@common_slick/Banner/ImageSlider';
import useStyles from '@common_slick/Banner/style';
import classNames from 'classnames';
import React, { useState } from 'react';
import Slider from 'react-slick';

const Arrow = (props) => (
    <svg
        className={`arrow ${props.left ? 'arrow--left' : 'arrow--right'}`}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="-7 -6 36 36"
        style={{ right: 0, left: 0, background: 'none' }}
    >
        {props.left && <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />}
        {!props.left && <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />}
    </svg>
);

const Banner = ({
    data = [],
    height,
    width,
    contentWidth = '',
    autoPlay = true,
    noLink = false,
    speed = 500,
    autoplaySpeed = 4000,
    storeConfig = {},
}) => {
    const styles = useStyles();
    const [slideIndex, setIndex] = useState(0);
    const [count, setCount] = useState(0);
    let sliderRef = React.createRef();

    const dotActive = data.length > 1 ? classNames(styles.dotsItem, styles.dotActive) : styles.hide;
    const dotItem = data.length > 1 ? styles.dotsItem : styles.hide;
    const handleLeftArrow = () => {
        if (slideIndex === 0) {
            sliderRef.slickPrev(data.length - 1);
        } else {
            sliderRef.slickPrev(slideIndex - 1);
        }
    };

    const handleRightArrow = () => {
        if (slideIndex === data.length - 1) {
            sliderRef.slickNext(0);
        } else {
            sliderRef.slickNext(slideIndex + 1);
        }
    };

    const settings = {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: autoPlay,
        speed,
        autoplaySpeed,
        rtl: false,
        arrows: false,
        afterChange: () => setCount(count + 1),
        beforeChange: (current, next) => setIndex(next),
    };

    return (
        <div className={styles.caraousel}>
            <Slider ref={(slider) => (sliderRef = slider)} {...settings}>
                {data.map((item, key) => (
                    <ImageSlide
                        storeConfig={storeConfig}
                        height={height}
                        width={width}
                        noLink={noLink}
                        contentWidth={contentWidth}
                        key={key}
                        {...item}
                    />
                ))}
            </Slider>
            {data.length > 1 ? (
                <>
                    <div className={classNames(styles.arrow, styles.leftArrow)} onClick={handleLeftArrow}>
                        <Arrow left />
                    </div>
                    <div className={classNames(styles.arrow, styles.rightArrow)} onClick={handleRightArrow}>
                        <Arrow />
                    </div>
                </>
            ) : null}
            <div className={styles.dots}>
                {data.map((item, id) => (
                    /* eslint-disable jsx-a11y/click-events-have-key-events */
                    /* eslint-disable jsx-a11y/no-static-element-interactions */
                    <div className={slideIndex === id ? dotActive : dotItem} key={id} onClick={() => sliderRef.slickGoTo(id)} />
                ))}
            </div>
        </div>
    );
};

export default Banner;
