import { gql, useLazyQuery } from '@apollo/client';
import { getLoginInfo } from '@root/core/helpers/auth';
import { useEffect } from 'react';

const GET_CUSTOMER_VERIFICATION_STATUS = gql`
    query getCustomerVerificationStatus {
        customer {
            verification_status
        }
    }
`;

export function useIsCustomerVerified() {
    const isLogin = getLoginInfo();
    const [fetch, { data }] = useLazyQuery(GET_CUSTOMER_VERIFICATION_STATUS, {
        context: {
            request: 'internal',
        },
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
    });

    useEffect(() => {
        if (isLogin) fetch();
    }, []);

    const verificationStatus = data?.customer?.verification_status;

    return verificationStatus === 'validated' || verificationStatus === 'verified';
}

export default { useIsCustomerVerified };
