import Banner from '@common_slick/Banner';
import useStyles from '@core_modules/home/pages/default/components/style';
import classNames from 'classnames';

const BannerView = (props) => {
    const { images, storeConfig, homepageSlider } = props;
    const styles = useStyles();
    return (
        <div className={classNames(styles.header)} id="home-banner">
            {images && images.length && <Banner data={images} showArrow storeConfig={storeConfig} homepageSlider={homepageSlider} />}
        </div>
    );
};

export default BannerView;
