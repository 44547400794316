/* eslint-disable object-curly-newline */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable operator-linebreak */
/* eslint-disable comma-dangle */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/accessible-emoji */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable max-len */
import Button from '@common_button';
import Thumbor from '@common_image';
import Loading from '@common_loaders/Backdrop';
import Typography from '@common_typography';
import { custDataNameCookie, recentSearch } from '@config';
import allData from '@core_modules/brands/helpers/generateAllData';
import SkeletonBrands from '@core_modules/brands/pages/default/components/skeleton';
import { getBrands } from '@core_modules/brands/services/graphql';
import WidgetSlider from '@core_modules/cms/components/cms-renderer/widget-slider';
import useStyles from '@core_modules/home/pages/default/components/style';
import { getAllCategory, getCustomer, getMission, getVoucher } from '@core_modules/home/service/graphql';
import { getLoginInfo } from '@helper_auth';
import { getLocalStorage, setLocalStorage } from '@helper_localstorage';
import { getStoreHost } from '@helpers/config';
import { useIsCustomerVerified } from '@helpers/customer';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import Skeleton from '@material-ui/lab/Skeleton';
import { getAppEnv } from '@root/core/helpers/env';
import { SECONDARY } from '@theme_color';
import classNames from 'classnames';
import Cookies from 'js-cookie';
import 'keen-slider/keen-slider.min.css';
import { useKeenSlider } from 'keen-slider/react';
import _ from 'lodash';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useRouter } from 'next/router';
import * as React from 'react';
import EvaIcon from 'react-eva-icons';

const BrowseModal = dynamic(() => import('@common_searchmodal'), { ssr: false });
const AllBrands = dynamic(() => import('@core_modules/brands/pages/default/components/all'), { ssr: true });

const Arrow = (props) => {
    const disabled = props.disabled ? ' arrow--disabled' : '';
    return (
        <svg
            onClick={props.onClick}
            className={`arrow ${props.left ? 'arrow--left' : 'arrow--right'} ${disabled}`}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="-7 -6 36 36"
        >
            {props.left && <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />}
            {!props.left && <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />}
        </svg>
    );
};

const RecentSearches = ({ show }) => {
    const styles = useStyles();
    const [searchData, setSearchData] = React.useState(getLocalStorage(recentSearch));

    const removeRecentSearch = (ids) => {
        const data = getLocalStorage(recentSearch);
        const newData = data.filter((item) => item.id !== ids);
        setSearchData(newData);
        setLocalStorage(recentSearch, newData);
    };

    const removeAllRecentSearch = () => {
        setSearchData([]);
        setLocalStorage(recentSearch, []);
    };

    if (!show && searchData && searchData.length > 0) return null;

    if (searchData && searchData.length === 0) return null;

    return (
        <>
            {searchData && (
                <Grid container spacing={2} className={styles.historyContainer}>
                    <Grid item xs={8}>
                        <Typography variant="h4" type="bold">
                            Terakhir Dicari
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="h5" type="bold" className={styles.historyDelete} onClick={() => removeAllRecentSearch()}>
                            Hapus Semua
                        </Typography>
                    </Grid>
                </Grid>
            )}
            <Grid container spacing={1} className={styles.historyContainerContent}>
                {searchData &&
                    searchData.map((item) => (
                        <>
                            <Grid item xs={11}>
                                <Link href={`/catalogsearch/result?q=${item.value}`} passHref>
                                    <Typography variant="h5" style={{ cursor: 'pointer' }}>
                                        {item.value}
                                    </Typography>
                                </Link>
                            </Grid>
                            <Grid item xs={1}>
                                <div onClick={() => removeRecentSearch(item.id)} className={styles.deleteIcon}>
                                    <EvaIcon
                                        name="close"
                                        size="large" // small, medium, large, xlarge
                                        fill={SECONDARY}
                                    />
                                </div>
                            </Grid>
                        </>
                    ))}
            </Grid>
        </>
    );
};

const Content = (props) => {
    const styles = useStyles();
    const [searchActive, setSearchActive] = React.useState(false);
    // const [showBanner, setShowBanner] = React.useState(true);
    const [fetchCustomer, manageCustomer] = getCustomer();
    const [keyword, setKeyword] = React.useState('');
    const [loadingScreen, setLoadingScreen] = React.useState(false);

    const isLogin = getLoginInfo();

    const router = useRouter();

    const { data: dataBrands, loading: loadingBrands } = getBrands({ pageSize: 8, currentPage: 1 });

    const { data: dataCategory, loading: loadingCategory } = getAllCategory({ fetchPolicy: 'network-only' });

    const allBrands = !loadingBrands && dataBrands && dataBrands.getBrandList && allData(dataBrands.getBrandList.items);

    React.useEffect(() => {
        if (isLogin) {
            fetchCustomer();
        }
    }, []);

    React.useEffect(() => {
        if (isLogin && manageCustomer && manageCustomer.data) {
            const { data } = manageCustomer;
            // if (data && data.customer && data.customer.verification_status !== 'unvalidated' && data.customer.verification_status !== 'unverified') {
            if (data && data.customer && data.customer.tnc_agreement) {
                if (_.get(data, 'customer', false) && _.get(window, 'ReactNativeWebView.postMessage', false)) {
                    window.ReactNativeWebView.postMessage(
                        JSON.stringify({
                            type: 'LOGGED_IN',
                            phoneNumber: data.customer.phonenumber.toString().replace(/\+/g, ''),
                            email: data.customer.email,
                            firstName: data.customer.firstname,
                            wpCode: data.customer.wp_code,
                        })
                    );
                }
                if (typeof smartech !== 'undefined') {
                    smartech('identify', `${data.customer.wp_code}`);
                    smartech('contact', '2', {
                        'pk^userid': data.customer.wp_code,
                        email: data.customer.email,
                        mobile: data.customer.phonenumber.toString().replace(/\+/g, ''),
                        NAME: data.customer.firstname,
                    });
                }
                Cookies.set(custDataNameCookie, {
                    email: data.customer.email,
                    firstname: data.customer.firstname,
                    lastname: data.customer.lastname,
                    middlename: data.customer.middlename,
                    wp_code: data.customer.wp_code,
                    customer_group: data.customer.customer_group,
                    phonenumber: data.customer.phonenumber,
                    is_phonenumber_valid: data.customer.is_phonenumber_valid,
                    verification_status: data.customer.verification_status,
                    tnc_agreement: data.customer.tnc_agreement,
                });
            } else if (
                data &&
                data.customer &&
                (data.customer.verification_status === 'unvalidated' || data.customer.verification_status === 'unverified')
            ) {
                router.push('/landing-page-apply-form');
            } else {
                router.push('/syarat-dan-ketentuan');
            }
        }
    }, [manageCustomer]);

    // eslint-disable-next-line no-unused-vars
    const [currentSlide, setCurrentSlide] = React.useState(0);
    const [loaded, setLoaded] = React.useState(false);
    const [sliderRef, instanceRef] = useKeenSlider({
        initial: 0,
        loop: false,
        slides: {
            perView: 4,
            spacing: 8,
        },
        slideChanged(slider) {
            setCurrentSlide(slider.track.details.rel);
        },
        created() {
            setLoaded(true);
        },
    });

    const generateRandomColor = () => {
        const randomColor = Math.floor(Math.random() * 16777215).toString(16);
        return `#${randomColor}`;
    };

    const urlDest = new URL(getStoreHost(getAppEnv()));

    const handleClickVerification = () => {
        router.push('/landing-page-apply-form');
    };

    const isCustomerVerified = useIsCustomerVerified();
    const showRecentSearches = isLogin && isCustomerVerified;

    // eslint-disable-next-line no-unused-vars
    const { loading: voucher_loading, data: data_voucher, error: voucher_error } = getVoucher({ skip: !isLogin });
    // eslint-disable-next-line no-unused-vars
    const { loading: mission_loading, data: data_mission, error: mission_error } = getMission({ skip: !isLogin });

    let missionList = [];
    if (data_mission && data_mission.getEligibleMission) {
        missionList = data_mission.getEligibleMission.missions;
    }

    if (loadingScreen) return <Loading open={loadingScreen} />;

    return (
        <div className={styles.container}>
            <>
                <BrowseModal {...props} searchActive={searchActive} setSearchActive={setSearchActive} keyword={keyword} setKeyword={setKeyword} />
                {searchActive ? (
                    <>
                        <RecentSearches show={showRecentSearches} />
                        {/* <Grid container spacing={1} className={styles.historyContainer}>
                            <Grid item xs={8}>
                                <Typography variant="h4" type="bold">
                                    Produk Pilihan 📣
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Link href="/shop" passHref>
                                    <Typography variant="h5" type="bold" className={styles.historyProductPilihan}>
                                        Lihat Semua &gt;
                                    </Typography>
                                </Link>
                            </Grid>
                            <Grid item xs={12}>
                                <WidgetSlider slider_id="12" storeConfig={props.storeConfig} />
                            </Grid>
                        </Grid> */}
                        <Grid container spacing={1} className={styles.historyContainer}>
                            <Grid item xs={12}>
                                <Typography variant="h4" type="bold">
                                    Bikin Juragan Makin Pintar
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Card>
                                    <CardActionArea>
                                        <CardContent>
                                            <Grid container spacing={1}>
                                                <Grid item xs="2">
                                                    <img
                                                        src="/assets/img/icon_komunitas_pintar.png"
                                                        alt="Komunitas Pintar"
                                                        style={{
                                                            height: 'auto',
                                                            width: 46,
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs="9">
                                                    <Typography variant="h4" type="bold">
                                                        Komunitas Pintar
                                                    </Typography>
                                                    <Typography variant="h5">Video, artikel atau poster tips bikin warung makin untung</Typography>
                                                </Grid>
                                                <Grid item xs="1">
                                                    <div style={{ paddingTop: '.5rem' }}>
                                                        <EvaIcon
                                                            name="chevron-right"
                                                            size="large" // small, medium, large, xlarge
                                                            fill={SECONDARY}
                                                        />
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} className={styles.historyContainer}>
                            <Grid item xs={12}>
                                <Typography variant="h4" type="bold">
                                    Misi Pintar
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Link href="/customer/account" passHref>
                                    <Card elevation={0}>
                                        <CardActionArea
                                            onClick={() => {
                                                // eslint-disable-next-line no-undef
                                                smartech('dispatch', 'br_misipintar_tap', {
                                                    source: 'Homepage',
                                                });
                                            }}
                                        >
                                            <CardContent>
                                                <Grid container spacing={1}>
                                                    <Grid item xs="2">
                                                        <picture>
                                                            <img
                                                                alt="Misi Pintar"
                                                                src="/assets/img/icon_misi_pintar.png"
                                                                style={{
                                                                    height: 'auto',
                                                                    width: 46,
                                                                }}
                                                            />
                                                        </picture>
                                                    </Grid>
                                                    <Grid item xs="9">
                                                        <Typography variant="h4" type="bold">
                                                            Makin Semangat Ngewarung
                                                        </Typography>
                                                        <Typography variant="h5">Selesaikan Misi dan Dapatkan Berbagai Hadiah Menarik</Typography>
                                                    </Grid>
                                                    <Grid item xs="1">
                                                        <div style={{ paddingTop: '.5rem' }}>
                                                            <EvaIcon
                                                                name="chevron-right"
                                                                size="large" // small, medium, large, xlarge
                                                                fill={SECONDARY}
                                                            />
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </Link>
                            </Grid>
                        </Grid>
                    </>
                ) : (
                    <>
                        {isLogin && manageCustomer && manageCustomer.data && manageCustomer.data.customer ? (
                            <>
                                {manageCustomer.data.customer.verification_status === 'unverified' && (
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Alert severity="info">
                                                Kelengkapan akunmu 80%. Lengkapi data sekarang!
                                                <br />
                                                <strong className={styles.linkVerification} onClick={() => handleClickVerification()}>
                                                    di sini!
                                                </strong>
                                            </Alert>
                                        </Grid>
                                    </Grid>
                                )}
                                {manageCustomer.data.customer.verification_status === 'on_progress' && (
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Alert severity="info">
                                                Lihat status pendaftaran untuk mengetahui verifikasimu.{' '}
                                                <Link href="/customer/account">
                                                    <strong className={styles.linkVerification}>Klik di sini!</strong>
                                                </Link>
                                            </Alert>
                                        </Grid>
                                    </Grid>
                                )}
                                {manageCustomer.data.customer.verification_status === 'unvalidated' && (
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Alert severity="error">
                                                Status verifikasi kamu ditolak, lihat selengkapnya{' '}
                                                <Link href="/customer/account">
                                                    <strong className={styles.linkVerification}>Klik di sini!</strong>
                                                </Link>
                                            </Alert>
                                        </Grid>
                                    </Grid>
                                )}
                            </>
                        ) : null}
                        <Grid container spacing={2} style={{ padding: '1rem' }}>
                            <Grid item xs={6}>
                                <Card elevation={1}>
                                    {isLogin ? (
                                        <Link href="/customer/vouchers" passHref>
                                            <CardActionArea
                                                onClick={() => {
                                                    // eslint-disable-next-line no-undef
                                                    smartech('dispatch', 'br_voucher_tap', {
                                                        source: 'Homepage',
                                                    });
                                                }}
                                            >
                                                <CardContent className={styles.cardContent}>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={3}>
                                                            <Avatar
                                                                variant="rounded"
                                                                alt="Voucher Saya"
                                                                src="/assets/img/warpin_icon_voucher_home.png"
                                                                className={styles.avatar}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={9}>
                                                            <Typography variant="h5" type="bold">
                                                                Voucher Saya
                                                            </Typography>
                                                            {!data_voucher && (voucher_loading || voucher_error) ? (
                                                                <Skeleton variant="text" />
                                                            ) : (
                                                                <>
                                                                    {data_voucher && data_voucher.listCouponCode && (
                                                                        <Typography variant="h5" className={styles.linkTopCardAction}>
                                                                            {data_voucher.listCouponCode.total_count === 0
                                                                                ? 'Voucher tidak tersedia'
                                                                                : `${data_voucher.listCouponCode.total_count} voucher tersedia >`}
                                                                        </Typography>
                                                                    )}
                                                                </>
                                                            )}
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </CardActionArea>
                                        </Link>
                                    ) : (
                                        <Link href="/customer/account/login" passHref>
                                            <CardActionArea
                                                onClick={() => {
                                                    // eslint-disable-next-line no-undef
                                                    smartech('dispatch', 'br_voucher_tap', {
                                                        source: 'Homepage',
                                                    });
                                                }}
                                            >
                                                <CardContent className={styles.cardContent}>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={3}>
                                                            <Avatar
                                                                variant="rounded"
                                                                alt="Voucher Saya"
                                                                src="/assets/img/warpin_icon_voucher_home.png"
                                                                className={styles.avatar}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={9}>
                                                            <Typography variant="h5" type="bold">
                                                                Voucher Saya
                                                            </Typography>
                                                            <Typography variant="h5" className={styles.linkTopCardAction}>
                                                                Daftar untuk melihat
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </CardActionArea>
                                        </Link>
                                    )}
                                </Card>
                            </Grid>
                            <Grid item xs={6}>
                                <Card>
                                    {isLogin ? (
                                        <Link href="/customer/mission" passHref>
                                            <CardActionArea
                                                onClick={() => {
                                                    // eslint-disable-next-line no-undef
                                                    smartech('dispatch', 'br_misipintar_tap', {
                                                        source: 'Homepage',
                                                    });
                                                }}
                                            >
                                                <CardContent className={styles.cardContent}>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={3}>
                                                            <Avatar
                                                                variant="rounded"
                                                                alt="Misi Pintar"
                                                                src="/assets/img/warpin_icon_misipintar_home.png"
                                                                className={styles.avatar}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={9}>
                                                            <Typography variant="h5" type="bold">
                                                                Misi Pintar
                                                            </Typography>
                                                            {mission_loading ? (
                                                                <Skeleton variant="text" />
                                                            ) : (
                                                                <Typography variant="h5" className={styles.linkTopCardAction}>
                                                                    {mission_error
                                                                        ? 'Misi tidak tersedia'
                                                                        : `${missionList && missionList.length} misi tersedia >`}
                                                                </Typography>
                                                            )}
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </CardActionArea>
                                        </Link>
                                    ) : (
                                        <Link href="/customer/account/login" passHref>
                                            <CardActionArea
                                                onClick={() => {
                                                    // eslint-disable-next-line no-undef
                                                    smartech('dispatch', 'br_misipintar_tap', {
                                                        source: 'Homepage',
                                                    });
                                                }}
                                            >
                                                <CardContent className={styles.cardContent}>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={3}>
                                                            <Avatar
                                                                variant="rounded"
                                                                alt="Misi Pintar"
                                                                src="/assets/img/warpin_icon_misipintar_home.png"
                                                                className={styles.avatar}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={9}>
                                                            <Typography variant="h5" type="bold">
                                                                Misi Pintar
                                                            </Typography>
                                                            <Typography variant="h5" className={styles.linkTopCardAction}>
                                                                Daftar untuk melihat
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </CardActionArea>
                                        </Link>
                                    )}
                                </Card>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} className={styles.historyContainer}>
                            <Grid item xs={12}>
                                <Typography variant="h4" type="bold">
                                    Spesial Untuk Juragan 🔥
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <WidgetSlider slider_id="13" storeConfig={props.storeConfig} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} className={styles.historyContainer}>
                            <Grid item xs={12}>
                                <Typography variant="h4" type="bold">
                                    Kategori Pilihan 🛒
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <div className="navigation-wrapper">
                                    {!loadingCategory && dataCategory && dataCategory.categoryList && (
                                        <>
                                            <div ref={sliderRef} className="keen-slider">
                                                {dataCategory.categoryList[0].children.map((item, index) => (
                                                    <Card
                                                        key={index}
                                                        style={{
                                                            backgroundImage: `linear-gradient(0deg, #fff -30%, ${generateRandomColor()} 45%`,
                                                        }}
                                                        className={classNames(styles.categoryCard, 'keen-slider__slide')}
                                                        onClick={() => {
                                                            // eslint-disable-next-line no-undef
                                                            smartech('dispatch', 'op_catalog_category_click', {
                                                                source: 'homepage',
                                                                category: item.name,
                                                            });
                                                            router.push(item.url_path);
                                                        }}
                                                    >
                                                        <CardContent className={styles.categoryCardContent}>
                                                            <Grid container spacing={2} columns={1}>
                                                                <Grid item xs={12} className={styles.viewtitleContent}>
                                                                    <Typography variant="h5" align="center" type="bold" color="white">
                                                                        {item.name}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={12} className={styles.viewimgContent}>
                                                                    {item.image_path.length > 0 ? (
                                                                        <Thumbor
                                                                            src={`${urlDest.protocol}//${urlDest.hostname}/${item.image_path}`}
                                                                            alt="logo category"
                                                                            width={100}
                                                                            height={100}
                                                                            quality={90}
                                                                            className={styles.imgContentCategory}
                                                                            storeConfig={props.storeConfig}
                                                                            classContainer={styles.transparent}
                                                                        />
                                                                    ) : null}
                                                                </Grid>
                                                            </Grid>
                                                        </CardContent>
                                                    </Card>
                                                ))}
                                            </div>
                                            {loaded && instanceRef.current && (
                                                <>
                                                    <Arrow left onClick={(e) => e.stopPropagation() || instanceRef.current?.prev()} />
                                                    <Arrow onClick={(e) => e.stopPropagation() || instanceRef.current?.next()} />
                                                </>
                                            )}
                                        </>
                                    )}
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} className={styles.historyContainer}>
                            <Grid item xs={12}>
                                <Button
                                    onClick={() => {
                                        setLoadingScreen(true);
                                        setTimeout(() => {
                                            if (isLogin) router.push('/catalogsearch/result?q=');
                                            else router.push('/customer/account/login');
                                        }, 1500);
                                    }}
                                    customRootStyle={{ width: '100%' }}
                                    className={styles.goToCheckout}
                                    fullWidth
                                >
                                    <Typography variant="span" color="secondary" type="bold" letter="uppercase">
                                        Lihat Semua Produk
                                    </Typography>
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} className={styles.historyContainer}>
                            <Grid item xs={8}>
                                <Typography variant="h4" type="bold">
                                    Halaman Brand
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Link href="/brands" passHref>
                                    <Typography variant="h4" type="bold" className={styles.historyProductPilihan}>
                                        Lihat Semua &gt;
                                    </Typography>
                                </Link>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item xs={12} className={styles.brandContainer}>
                                {loadingBrands && <SkeletonBrands {...props} />}
                                {!loadingBrands && allBrands && <AllBrands all={allBrands} {...props} />}
                            </Grid>
                        </Grid>
                        {/* <Grid container spacing={1} className={styles.historyContainer}>
                            <Grid item xs={8}>
                                <Typography variant="h4" type="bold">
                                    Produk Pilihan 📣
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Link href="/shop" passHref>
                                    <Typography variant="h4" type="bold" className={styles.historyProductPilihan}>
                                        Lihat Semua &gt;
                                    </Typography>
                                </Link>
                            </Grid>
                            <Grid item xs={12}>
                                <WidgetSlider slider_id="14" storeConfig={props.storeConfig} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} className={styles.historyContainer}>
                            <Grid item xs={8}>
                                <Typography variant="h4" type="bold">
                                    Komunitas Pintar
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Link href="/" passHref>
                                    <Typography variant="h4" type="bold" className={styles.historyProductPilihan}>
                                        Lihat Semua &gt;
                                    </Typography>
                                </Link>
                            </Grid>
                            <Grid item xs={12}>
                                <WidgetSlider slider_id="14" storeConfig={props.storeConfig} />
                            </Grid>
                        </Grid> */}
                    </>
                )}
                {/* <PromoBanner setShowBanner={setShowBanner} open={showBanner} /> */}
            </>
        </div>
    );
};

export default Content;
