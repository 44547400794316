/* eslint-disable operator-linebreak */
/* eslint-disable object-curly-newline */
/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-danger */
/* eslint-disable max-len */

import { useApolloClient } from '@apollo/client';
import classNames from 'classnames';
import Cookies from 'js-cookie';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';
import TagManager from 'react-gtm-module';
// eslint-disable-next-line object-curly-newline
import { assetsVersion, custDataNameCookie, features, modules, storeConfigNameCookie } from '@config';
import useStyles from '@core_modules/theme/layout/style';
import { getHost } from '@helper_config';
import { getCookies, setCookies } from '@helper_cookies';
import { setLocalStorage } from '@helper_localstorage';
import { breakPointsDown, breakPointsUp } from '@helper_theme';
import crypto from 'crypto';

import { getCountCart } from '@core_modules/theme/services/graphql';
import { getCartId } from '@helper_cartid';
import { localTotalCart } from '@services/graphql/schema/local';

const GlobalPromoMessage = dynamic(() => import('@core_modules/theme/components/globalPromo'), { ssr: false });
const BottomNavigation = dynamic(() => import('@common_bottomnavigation'), { ssr: false });
const HeaderMobile = dynamic(() => import('@common_headermobile'), { ssr: true });
const Message = dynamic(() => import('@common_toast'), { ssr: false });
const Loading = dynamic(() => import('@common_loaders/Backdrop'), { ssr: false });
const ScrollToTop = dynamic(() => import('@common_scrolltotop'), { ssr: false });
const RestrictionPopup = dynamic(() => import('@common_restrictionPopup'), { ssr: false });
const NewsletterPopup = dynamic(() => import('@core_modules/theme/components/newsletterPopup'), { ssr: false });
const RecentlyViewed = dynamic(() => import('@core_modules/theme/components/recentlyViewed'), { ssr: false });

const fromEntriesPolyfills = (iterable) =>
    [...iterable].reduce((obj, [key, val]) => {
        // eslint-disable-next-line no-param-reassign
        obj[key] = val;
        return obj;
    }, {});

const Layout = (props) => {
    const bodyStyles = useStyles();
    const {
        pageConfig,
        children,
        CustomHeader = false,
        i18n,
        storeConfig = {},
        isLogin,
        headerProps = {},
        data = {},
        t,
        onlyCms,
        withLayoutHeader = true,
        // withLayoutFooter = true,
        showRecentlyBar = false,
        isHomepage = false,
        isPdp = false,
        isCms = false,
        isPlp = false,
        isBdp = false,
        isBlp = false,
        isCheckout = false,
        isLoginPage = false,
    } = props;
    const { ogContent = {}, schemaOrg = null, headerDesktop = true, footer = true } = pageConfig;
    const router = useRouter();
    const [stateAwp, setStateAwp] = useState({
        toastMessage: {
            open: false,
            variant: 'success',
            text: '',
        },
        backdropLoader: false,
    });
    const [restrictionCookies, setRestrictionCookies] = useState(false);
    const [showGlobalPromo, setShowGlobalPromo] = React.useState(false);

    // get app name config

    let appName = '';
    let installMessage = '';
    let showPopup = false;
    let iconAppleTouch = '/assets/img/swiftpwa_apple_touch.png';
    if (storeConfig && storeConfig.pwa) {
        iconAppleTouch = storeConfig.pwa.icon_apple_touch;
        appName = storeConfig.pwa.app_name;
        showPopup = storeConfig.pwa.custom_install_app_enable;
        installMessage = storeConfig.pwa.install_message || 'Install';
    }

    // const [mainMinimumHeight, setMainMinimumHeight] = useState(0);
    const refFooter = useRef(null);
    const refHeader = useRef(null);
    const client = useApolloClient();

    const handleSetToast = (message) => {
        setStateAwp({
            ...stateAwp,
            toastMessage: {
                ...stateAwp.toastMessage,
                ...message,
            },
        });
    };

    const handleLoader = (status = false) => {
        setStateAwp({
            ...stateAwp,
            backdropLoader: status,
        });
    };

    const handleCloseMessage = () => {
        setStateAwp({
            ...stateAwp,
            toastMessage: {
                ...stateAwp.toastMessage,
                open: false,
            },
        });
    };

    const handleRestrictionCookies = () => {
        setRestrictionCookies(true);
        setCookies('user_allowed_save_cookie', true);
    };

    const handleClosePromo = () => {
        setShowGlobalPromo(false);
    };

    const allowHeaderCheckout = modules.checkout.checkoutOnly ? !modules.checkout.checkoutOnly : withLayoutHeader;

    const ogData = {
        'og:image:type': 'image/png',
        'og:locale': i18n && i18n.language === 'id' ? 'id_ID' : 'en_US',
        ...ogContent,
    };

    if (!ogData['og:description']) {
        ogData['og:description'] = storeConfig.default_description || '';
    }

    if (!ogData['og:title']) {
        ogData['og:title'] = pageConfig.title ? pageConfig.title : storeConfig.default_title ? storeConfig.default_title : 'Swift Pwa' || '';
    }

    if (!ogData['og:image']) {
        ogData['og:image'] = storeConfig.header_logo_src
            ? `${storeConfig.secure_base_media_url}logo/${storeConfig.header_logo_src}`
            : `${getHost()}/assets/img/swift-logo.png` || '';
    }

    if (!ogData['og:url']) {
        ogData['og:url'] = `${getHost()}${router.asPath}` || '';
    }

    if (!ogData['og:type']) {
        ogData['og:type'] = 'website';
    }

    if (storeConfig && storeConfig.pwa && storeConfig.pwa.facebook_meta_id_app_id) {
        ogData['fb:app_id'] = storeConfig.pwa.facebook_meta_id_app_id;
    }

    const reloadCartQty = typeof window !== 'undefined' && window && window.reloadCartQty;
    let cartId = '';
    const [getCart, RespondCart] = getCountCart();
    if (typeof window !== 'undefined') {
        cartId = getCartId();
    }

    useEffect(() => {
        if (RespondCart && RespondCart.data) {
            client.writeQuery({
                query: localTotalCart,
                data: { totalCart: RespondCart.data.cart.total_quantity },
            });
        }
    }, [RespondCart]);

    useEffect(() => {
        if (reloadCartQty && cartId) {
            // query get cart
            getCart({
                variables: {
                    cartId,
                },
            });
            window.reloadCartQty = false;
        }
    }, [reloadCartQty]);

    useEffect(() => {
        const isRestrictionMode = getCookies('user_allowed_save_cookie');
        if (isRestrictionMode) {
            setRestrictionCookies(isRestrictionMode);
        }
        if (typeof window !== 'undefined') {
            window.toastMessage = handleSetToast;
            window.backdropLoader = handleLoader;
            const custData = Cookies.getJSON(custDataNameCookie);
            const enablePromo = getCookies(features.globalPromo.key_cookies);
            const tagManagerArgs = {
                dataLayer: {
                    pageName: pageConfig.title,
                    pageType: pageConfig.pageType || 'other',
                    customerGroup: isLogin === 1 ? 'GENERAL' : 'NOT LOGGED IN',
                },
            };
            if (custData && custData.email) {
                const custEmail = custData.email.toLowerCase();
                tagManagerArgs.dataLayer.eid = crypto.createHash('sha256').update(custEmail).digest('hex');
            }
            if (custData && custData.phonenumber && custData.is_phonenumber_valid) {
                let custPhone = custData.phonenumber;
                custPhone = `${custPhone}`;
                tagManagerArgs.dataLayer.pid = crypto.createHash('sha256').update(custPhone).digest('hex');
            }
            TagManager.dataLayer(tagManagerArgs);
            if (enablePromo !== '' && storeConfig.global_promo && storeConfig.global_promo.enable) {
                setShowGlobalPromo(enablePromo);
            } else if (storeConfig.global_promo && storeConfig.global_promo.enable) {
                setShowGlobalPromo(true);
            }
        }
        // setMainMinimumHeight(refFooter.current.clientHeight + refHeader.current.clientHeight);
    }, []);

    const desktop = breakPointsUp('md');

    const ipadUp = breakPointsUp('sm');
    const ipadDown = breakPointsDown('md');

    const ipadLUp = breakPointsUp('md');
    const ipadLDown = breakPointsDown('lg');

    const ipad = !!(ipadUp && ipadDown);
    const ipadL = !!(ipadLUp && ipadLDown);

    const styles = {
        marginBottom:
            pageConfig.bottomNav && storeConfig?.pwa?.mobile_navigation === 'bottom_navigation' && storeConfig?.pwa?.enabler_footer_mobile === true
                ? '45px'
                : 0,
        marginTop: storeConfig?.pwa?.mobile_navigation === 'burger_menu' && !isHomepage && !isPdp ? '55px' : 0,
    };

    // const footerMobile = {
    //     marginBottom: pageConfig.bottomNav && storeConfig.pwa && storeConfig.pwa.mobile_navigation === 'bottom_navigation' ? '55px' : 0,
    //     display: pageConfig.bottomNav && storeConfig.pwa && storeConfig.pwa.mobile_navigation === 'bottom_navigation' ? 'flex' : null,
    // };

    if (!headerDesktop) {
        styles.marginTop = 0;
    }

    if (typeof window !== 'undefined' && storeConfig) {
        const arrayStoreConfig = Object.entries(storeConfig);
        // eslint-disable-next-line no-unused-vars, consistent-return, array-callback-return
        const filteredStoreConfig = arrayStoreConfig.filter(([key, value]) => {
            if (
                key !== 'snap_is_production' &&
                key !== 'snap_client_key' &&
                key !== 'firebase_api_key' &&
                key !== 'paypal_key' &&
                key !== 'swift_server' &&
                !key.includes('payment_travelokapay_')
            ) {
                return true;
            }
        });
        const excludePrivateStoreConfig = fromEntriesPolyfills(filteredStoreConfig);
        setLocalStorage(storeConfigNameCookie, excludePrivateStoreConfig);
    }

    let classMain;

    if (storeConfig && storeConfig.pwa && storeConfig.pwa.enabler_sticky_header) {
        if (isCheckout) {
            classMain = 'checkout-mode';
        } else if (storeConfig.pwa.header_version === 'v2') {
            if (isHomepage) {
                if (ipadL) {
                    classMain = 'main-app-v2-ipad-landscape';
                } else {
                    classMain = 'main-app-v2';
                }
                classMain += ' main-app-homepage';
            } else if (isPdp && desktop) {
                classMain = 'main-app-v2-pdp';
            } else if (isLoginPage && desktop) {
                classMain = 'main-app-v2-login';
            } else if (isPdp && ipad && !desktop) {
                classMain = 'main-app-sticky-v2-ipad';
            } else {
                classMain = 'main-app-v2-not-homepage';
            }
        } else if (storeConfig.pwa.header_version === 'v1') {
            if (isHomepage) {
                classMain = 'main-app-v1-sticky-homepage';
            } else if (router && router.route && router.route.includes('customer/account/warung-verification/data/address-form')) {
                classMain = 'main-app-v1-sticky-not-homepage-address-form';
            } else {
                classMain = 'main-app-v1-sticky-not-homepage';
            }
        } else if (storeConfig.pwa.header_version === 'v4') {
            if (isHomepage) {
                if (ipad) {
                    if (storeConfig.pwa.mobile_navigation === 'burger_menu') {
                        classMain = 'main-app-sticky-v4-homepage';
                    } else {
                        classMain = 'main-app-sticky-v4-homepage-not-burgermenu';
                    }
                } else {
                    classMain = 'main-app-sticky-v4-homepage';
                }
            } else if (isPdp) {
                if (ipad) {
                    classMain = 'main-app-sticky-v4-pdp-ipad';
                } else {
                    classMain = 'main-app-sticky-v4-pdp';
                }
            } else {
                classMain = 'main-app-sticky-v4';
            }
        } else if (isHomepage) {
            classMain = 'main-app-sticky-homepage';
        } else {
            classMain = 'main-app-sticky';
        }
    } else if (storeConfig && storeConfig.pwa && !storeConfig.pwa.enabler_sticky_header) {
        if (isCheckout) {
            classMain = 'checkout-mode';
        } else if (storeConfig.pwa.header_version === 'v2') {
            if (isHomepage) {
                classMain = 'main-app-v2-not-sticky';
                classMain += ' main-app-homepage';
            } else if (isPdp && ipad) {
                classMain = 'main-app-v2-ipad';
            } else {
                classMain = 'main-app-v2-not-sticky-not-homepage';
            }
        } else if (storeConfig.pwa.header_version === 'v4') {
            classMain = 'main-app-not-sticky';
        } else {
            classMain = 'main-app-not-sticky';
        }
    }

    let metaDescValue = ogData['og:description'];
    let metaTitleValue = ogData['og:title'];
    let metaKeywordValue = pageConfig.title ? pageConfig.title : storeConfig.default_title ? storeConfig.default_title : 'Swift Pwa';

    if (isPlp) {
        metaDescValue = data && data?.meta_description ? data?.meta_description : ogData['og:description'];
        metaTitleValue = data && data?.meta_title ? data?.meta_title : ogData['og:title'];
        metaKeywordValue = data && data?.meta_keywords ? data?.meta_keywords : '';
    }
    if (isPdp) {
        metaDescValue = data && data.products?.items[0].meta_description ? data.products?.items[0].meta_description : ogData['og:description'];
        metaTitleValue = data && data.products?.items[0].meta_title ? data.products?.items[0].meta_title : ogData['og:title'];
        metaKeywordValue = data && data.products?.items[0].meta_keyword ? data.products?.items[0].meta_keyword : '';
    }
    if (isCms) {
        metaDescValue = data && data.cmsPage?.meta_description ? data.cmsPage?.meta_description : ogData['og:description'];
        metaTitleValue = data && data.cmsPage?.meta_title ? data.cmsPage?.meta_title : ogData['og:title'];
        metaKeywordValue = data && data.cmsPage?.meta_keywords ? data.cmsPage?.meta_keywords : '';
    }
    if (isBdp) {
        metaDescValue = data && data?.meta_description ? data?.meta_description : ogData['og:description'];
        metaTitleValue = data && data?.meta_title ? data?.meta_title : ogData['og:title'];
        metaKeywordValue = data && data?.meta_keywords ? data?.meta_keywords : '';
    }
    if (isBlp) {
        const dataBlp = data?.getBlogCategory?.data[0];
        metaDescValue = data && dataBlp.meta_description ? dataBlp.meta_description : ogData['og:description'];
        metaTitleValue = data && dataBlp.meta_title ? dataBlp.meta_title : ogData['og:title'];
        metaKeywordValue = data && dataBlp.meta_keywords ? dataBlp.meta_keywords : '';
    }
    return (
        <>
            <Head>
                <meta name="keywords" content={metaKeywordValue} />
                <meta name="robots" content="NOINDEX,NOFOLLOW" />
                <link rel="apple-touch-icon" href={iconAppleTouch} />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="format-detection" content="telephone=no" />
                <meta name="title" content={metaTitleValue} />
                <meta name="description" content={metaDescValue} />
                {Object.keys(ogData).map((key, idx) => {
                    let valueWithMeta = ogData[key];
                    if (key === 'og:description') {
                        valueWithMeta = metaDescValue;
                    }
                    if (key === 'og:title') {
                        valueWithMeta = metaTitleValue;
                    }
                    if (typeof ogData[key] === 'object' && ogData[key].type && ogData[key].type === 'meta') {
                        valueWithMeta = ogData[key].value;
                        if (key === 'description') {
                            valueWithMeta = metaDescValue;
                        }
                        if (key === 'title') {
                            valueWithMeta = metaTitleValue;
                        }
                        return <meta name={`${key}`} content={valueWithMeta} key={idx} />;
                    }
                    return <meta property={`${key}`} content={valueWithMeta} key={idx} />;
                })}
                <title>{pageConfig.title ? pageConfig.title : storeConfig.default_title ? storeConfig.default_title : 'Swift Pwa'}</title>
                {schemaOrg
                    ? schemaOrg.map((val, idx) => (
                          <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(val) }} key={idx} />
                      ))
                    : null}
                {showPopup && <script src={`/static/firebase/install.${assetsVersion}.js`} defer />}
            </Head>
            {allowHeaderCheckout && (
                <header ref={refHeader}>
                    {typeof window !== 'undefined' && storeConfig.global_promo && storeConfig.global_promo.enable && (
                        <GlobalPromoMessage
                            t={t}
                            storeConfig={storeConfig}
                            showGlobalPromo={showGlobalPromo}
                            handleClose={handleClosePromo}
                            appName={appName}
                            installMessage={installMessage}
                        />
                    )}
                    {React.isValidElement(CustomHeader) ? (
                        <>{React.cloneElement(CustomHeader, { pageConfig, ...headerProps })}</>
                    ) : (
                        <HeaderMobile pageConfig={pageConfig} storeConfig={storeConfig} {...headerProps} isCheckout />
                    )}
                </header>
            )}
            <div className="main-wrapper">
                <main style={{ ...styles }} className={classNames(!onlyCms ? 'main-app' : 'main-app main-app-cms', classMain)} id="maincontent">
                    <Loading open={stateAwp.backdropLoader} />
                    <Message
                        open={stateAwp.toastMessage.open}
                        variant={stateAwp.toastMessage.variant}
                        setOpen={handleCloseMessage}
                        message={stateAwp.toastMessage.text}
                    />
                    {storeConfig.weltpixel_newsletter_general_enable === '1' && (
                        <NewsletterPopup t={t} storeConfig={storeConfig} pageConfig={pageConfig} isLogin={isLogin} />
                    )}
                    {children}
                    {desktop ? <ScrollToTop {...props} /> : null}
                </main>
                <footer className={bodyStyles.footerContainer} ref={refFooter}>
                    <BottomNavigation active={pageConfig.bottomNav} storeConfig={storeConfig} pageConfig={pageConfig} />
                </footer>
                {storeConfig.cookie_restriction && !restrictionCookies && (
                    <RestrictionPopup handleRestrictionCookies={handleRestrictionCookies} restrictionStyle={bodyStyles.cookieRestriction} />
                )}
                {showRecentlyBar && !onlyCms && (
                    <RecentlyViewed
                        isActive={storeConfig && storeConfig.weltpixel_RecentlyViewedBar_general_enable}
                        recentlyBtn={bodyStyles.recentView}
                        wrapperContent={bodyStyles.recentlyWrapperContent}
                        recentlyBtnContent={bodyStyles.recentlyBtnContent}
                        contentFeatured={bodyStyles.contentFeatured}
                        className={bodyStyles.itemProduct}
                    />
                )}
            </div>
        </>
    );
};

export default Layout;
