/* eslint-disable max-len */
import Skeleton from '@common_skeleton';
import useStyles from '@core_modules/home/pages/default/components/style';
import _ from 'lodash';

const BannerSliderSkeleteon = (props) => {
    const { storeConfig } = props;
    const styles = useStyles();
    return (
        <div className={styles.skeletonWrapper}>
            {storeConfig && (
                <Skeleton
                    variant="rect"
                    animation="wave"
                    xsStyle={{ width: '100%', height: `${_.get(storeConfig?.pwa, 'home_slider_mobile_height', '376')}px` }}
                    mdStyle={{ width: '100%', height: `${_.get(storeConfig?.pwa, 'home_slider_desktop_height', '376')}px` }}
                />
            )}
        </div>
    );
};

export default BannerSliderSkeleteon;
