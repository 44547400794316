import { makeStyles } from '@material-ui/core/styles';
import { BLACK30, PRIMARY, WHITE } from '@theme_color';
import { Centering } from '@theme_mixins';

const useStyles = makeStyles((theme) => ({
    caraousel: {
        width: '100%',
        height: '100%',
        position: 'relative',
        [theme.breakpoints.up('sm')]: {
            height: 'auto',
        },
    },
    dots: {
        zIndex: 2,
        display: 'flex',
        flexDirection: 'row',
        position: 'absolute',
        justifyContent: 'space-arround',
        bottom: '-33px',
        left: '8.5%',
        transform: 'translate(-50%, -50%)',
    },
    dotsItem: {
        width: 10,
        height: 8,
        borderRadius: 100,
        backgroundColor: BLACK30,
        margin: 5,
        cursor: 'pointer',
    },
    dotActive: {
        backgroundColor: PRIMARY,
        width: 24,
        height: 8,
    },
    hide: {
        display: 'none',
    },
    imageSlider: {
        display: 'flex',
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            height: 'auto',
        },
    },
    imageSliderAuto: {
        [theme.breakpoints.up('sm')]: {
            width: 'auto !important',
        },
    },
    thumborContainer: {
        backgroundColor: '#eee',
        width: '100%',
        position: 'relative',
        [theme.breakpoints.up('sm')]: {
            height: 'auto',
            paddingTop: 0,
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: WHITE,
        },
    },
    thumborImage: {
        width: '100%',
        height: '100%',
        [theme.breakpoints.up('sm')]: {
            height: 'auto',
            position: 'unset',
        },
    },
    arrow: {
        fontSize: '2rem',
        backgroundColor: WHITE,
        position: 'absolute',
        ...Centering,
        padding: 10,
        borderRadius: '50%',
        textAlign: 'center',
        top: 'calc(50% - 1rem)',
        width: 20,
        height: 20,
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: PRIMARY,
            color: WHITE,
        },
    },
    leftArrow: {
        left: 5,
        '& svg': {
            left: '0px !important',
        },
    },

    rightArrow: {
        right: 5,
    },
    productVideo: {
        backgroundColor: '#eee',
        width: '100%',
        position: 'relative',
        paddingTop: '116%',
        marginTop: '0',
        '& iframe': {
            position: 'absolute',
            top: '0',
            height: '100%',
        },
        [theme.breakpoints.up('sm')]: {
            height: 'auto',
            paddingTop: 0,
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: WHITE,
            '& iframe': {
                position: 'absolute',
                top: '0',
                height: '600px',
            },
        },
    },
}));

export default useStyles;
